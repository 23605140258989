import styled from "styled-components";

export const SecaoAdmin = styled.section`
    background-color: white;
    color: var(--corNugaliEscura);
    width: 100%;
    height: 100vh;
    display: flex;
`
export const SecaoPrincipal = styled.div`
    flex: 1;
    overflow-y: auto;
`
export const PaginasTabela = styled.div`
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    margin: 10px 50px;
    font-size: 14px;

    p {
        padding: 5px;
        margin: 0 5px;
        cursor: pointer;
        border-radius: 3px;
        border: 1px solid white;
        color: #252525;

        &:hover {
            border: 1px solid gray;
        }
    }
`
export const OpcoesFiltragem = styled.div`
    display: flex;
    margin: ${props => props.$margem || "0"};

    .Icone {
        cursor: pointer;
        padding-left: 0px;
        margin-top: 25px;
        width: 2em;
        height: 1.5em;
    }
`
export const FiltrarData = styled.div`
    display: block;
    margin-right: 15px;
    margin-top: ${props => props.$marginTop || "0"};

    input {
        display: block;
    }
`
export const BotaoFiltro = styled.button`
    background-color: transparent;
    border: none;
    color: var(--corNugaliEscura);
    cursor: pointer;
    margin-top: 10px;
    display: ${props => props.$mostrar? "block": "none"};
`
export const InputMes = styled.input`
    color: #252525;
    border: 1px solid #252525;
    border-radius: 2px;
    padding: 5px;
    width: 100px;
`
export const RodapePaginas = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`
export const TotalDocs = styled.div`
    display: flex;
    margin-left: 40px;
    color: #252525;
    font-size: 14px;
`
export const LinksPaginas = styled.a`
    padding: 5px 10px;
    margin: 5px 0;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    color: ${props => props.$cor || "#252525"};
    background-color: ${props => props.$corFundo || "white"};
    border: 1px solid white;

    &:hover {
        border: 1px solid gray;
        color: ${props => props.$cor || "#05031de5"};
        background-color: ${props => props.$corFundo || "white"};
    }
`
export const AreaSuperior = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: ${props => props.$margem || "10px 30px 0 40px"};
    border-bottom: ${props => props.$borda || "none"};
`
export const BotaoExcluir = styled.button`
    margin: 5px;
    padding: 2px 5px;
    cursor: pointer;
    background-color: #ffffff;
    color: #ff1c1c;
    border-radius: 3px;
    border: 1px solid white;

    &:hover {
        background-color: var(--corNugaliEscura);
        color: white;
        border: 1px solid var(--corNugaliEscura);
    }
`
export const BotaoAcoes = styled.button`
    margin: 5px;
    padding: 2px 5px;
    cursor: pointer;
    background-color: var(--corNugaliClara);
    color: var(--corNugaliEscura);
    border-radius: 3px;
    border: 1px solid white;

    &:hover {
        background-color: var(--corNugaliEscura);
        color: white;
        border: 1px solid var(--corNugaliEscura);
    }
`
export const TituloColunas = styled.th`
    cursor: pointer;
    p {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        margin: 0;
        color: #252525;
    }
    .Icone {
        position: relative;
        top: 4px;
        left: 3px;
        margin: 0 2px;
        padding: 0;
    }
`
export const Tabela = styled.table`
    width: 90%;
    justify-content: center;
    text-align: left;
    border-collapse: collapse;
    margin: auto;
    margin-top: 10px;
    overflow-x: auto;

    input {
        box-sizing: border-box;
        padding: 9px;
        font-size: 14px;
        font-family: "Jost",sans-serif;
        border-radius: 2px;
        border: 1px solid rgba(58, 23, 14, 0.308);

        ::-webkit-inner-spin-button{
            -webkit-appearance: none; 
            margin: 0; 
        }
        ::-webkit-outer-spin-button{
            -webkit-appearance: none; 
            margin: 0; 
        }
        &:focus {
            border-bottom: 1px solid green;
            outline: none;
        }
        &:-webkit-autofill {
            -webkit-background-clip: text;
            box-shadow: inset 0 0 20px 20px transparent;
            -webkit-text-fill-color: #3a170e;
            border: 2px solid rgba(58, 23, 14, 0.308);
        }
    }
`
export const LinhaTabela = styled.tr`
    border-bottom: 1px solid lightgray;
    height: ${props => props.$tamanho || "40px"};
`
export const ColunaTabela = styled.td`
    width: ${props => props.$tamanho || "auto"};
    color: ${props => props.$cor || "inherit"};
    font-size: ${props => props.$tamFonte || "inherit"};

    .Icone {
        margin: 5px 5px 0 0;
        padding: 0;
    }

    input {
        margin: 0;
        width: 100%;

        &:focus {
            border-bottom: 2px solid;
        }

        &:-webkit-autofill {
            -webkit-background-clip: text;
            box-shadow: inset 0 0 20px 20px transparent;
            -webkit-text-fill-color: #3a170e;
            border: 2px solid rgba(58, 23, 14, 0.308);
        }

        ::-webkit-inner-spin-button{
            -webkit-appearance: none; 
            margin: 0; 
        }
        ::-webkit-outer-spin-button{
            -webkit-appearance: none; 
            margin: 0; 
        }
    }
`
export const InputsGuia = styled.input`
    width: 100%;
    padding: 5px;
    display: block;
    border-radius: 5px;
    border: 2px solid rgba(58, 23, 14, 0.308);
    color: var(--corNugaliEscura);
    background-color: transparent;
    outline: none;
    min-width: 180px;
    font-size: 16px;
    transition: all .2s ease-out;
    margin-bottom: 20px;
    appearance : none;
    box-sizing: border-box;
    
    ::-webkit-inner-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }
    &:focus {
        border-bottom: 2px solid;
    }
    &:-webkit-autofill {
        -webkit-background-clip: text;
        box-shadow: inset 0 0 20px 20px transparent;
        -webkit-text-fill-color: #3a170e;
        border: 2px solid rgba(58, 23, 14, 0.308);
    }
`
export const Inline = styled.div`
    display: inline-flex;
`
export const MarkObrigatorio = styled.p`
    color: red;
    margin: 0;
    padding: 0;
    padding-top: 0;
`
export const DivFormulario = styled.div`
    border-radius: 5px;
    border: none;
    max-width: 500px;
    margin: 0 50px;
    padding: 10px 0;

    label {
        color: var(--corNugaliEscura);
        font: 1em sans-serif;
        font-weight: bold;
        margin-top: 10px;
    }

    input {
        width: 100%;
        padding: 5px;
        display: block;
        border-radius: 5px;
        border: 2px solid rgba(58, 23, 14, 0.308);
        color: var(--corNugaliEscura);
        background-color: transparent;
        outline: none;
        min-width: 180px;
        font-size: 16px;
        transition: all .2s ease-out;
        margin-bottom: 20px;
        appearance : none;
        box-sizing: border-box;
        
        ::-webkit-inner-spin-button{
            -webkit-appearance: none; 
            margin: 0; 
        }
        ::-webkit-outer-spin-button{
            -webkit-appearance: none; 
            margin: 0; 
        }
        &:focus {
            border-bottom: 2px solid;
        }
        &:-webkit-autofill {
            -webkit-background-clip: text;
            box-shadow: inset 0 0 20px 20px transparent;
            -webkit-text-fill-color: #3a170e;
            border: 2px solid rgba(58, 23, 14, 0.308);
        }
    }
`
export const AreaBotoesAdd = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
`
export const Inline2 = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`
export const AreaGrafico = styled.div`
    width: ${props => props.$largura || "90%"};
    margin: ${props => props.$margem || "30px 50px 30px 50px"};
    max-width: 630px;
    align-items: center;
    justify-content: center;
`
export const ImportarArquivo = styled.input`
    display: none;
`
export const ArquivoEstilo = styled.label`
    border: 1px solid;
    border-color: var(--corNugaliEscura);
    background-color: ${props => props.$corFundo || "transparent"};
    color: ${props => props.$cor || "#3a170e"};
    padding: 7px 5px 1px 5px;
    font-weight: bold;
    font-size: 18px;
    height: 30px;
    margin: 25px 15px 10px 0px;
    border-radius: 7px;
    outline: none;
    box-shadow: none;
    transition: border-color 0.25s;
    cursor: pointer;

    &:hover{
        box-shadow: 2px 1px 5px 1px lightgray;
    }
    &:disabled{
        color: lightgray;
        border-color: lightgray;
        cursor: "wait";
        box-shadow: none;
    }
`