import { Link } from "react-router-dom";
import styled from "styled-components";
import { FaTicketAlt } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { IoMdHome } from "react-icons/io";
import { GrSchedule } from "react-icons/gr";
import { FaUserCog } from "react-icons/fa";
import { FaPersonHiking } from "react-icons/fa6";
import { GiReceiveMoney } from "react-icons/gi";
import { MdHistory } from "react-icons/md";
import { AiOutlineFileAdd } from "react-icons/ai";

const BarraLateral = styled.div`
    flex: 0 0 200px;
    /* width: 250px; */
    background-color: #f3f4f7;
    color: #090538;
    border-right: 1px solid #aaaaaa;
    align-items: center;    
`
const ItemMenu = styled.p`
    cursor: pointer;
    color: #090538;
    margin: ${props => props.$margem || "10px auto 10px 30px"};
    font-size: ${props => props.$fonte || "16px"};
    display: flex;
    align-items: center;

    .Icone {
        margin: 0 8px 0 0;
    }
`
export default function MenuLateral(){
    return (
        <BarraLateral>
            <nav>
                <Link style={{ textDecoration: 'none', color: "inherit" }} to="/admin"><ItemMenu  $margem="30px auto 40px 30px" $fonte="20px"><IoMdHome className="Icone"/>Início</ItemMenu></Link>
                <Link style={{ textDecoration: 'none', color: "inherit" }} to="/admin/visitantes"><ItemMenu><FaUserAlt className="Icone"/>Visitantes</ItemMenu></Link>
                <Link style={{ textDecoration: 'none', color: "inherit" }} to="/admin/ingressos?pagina=1&ordenacao=numero&ordem=-1"><ItemMenu><FaTicketAlt className="Icone"/> Ingressos</ItemMenu></Link>
                <Link style={{ textDecoration: 'none', color: "inherit" }} to="/admin/sessoes"><ItemMenu><GrSchedule className="Icone"/>Sessões</ItemMenu></Link>
                <Link style={{ textDecoration: 'none', color: "inherit" }} to="/criar-tickets"><ItemMenu $margem="10px auto 40px 30px"><AiOutlineFileAdd className="Icone"/>Criar ingressos</ItemMenu></Link>
                <Link style={{ textDecoration: 'none', color: "inherit" }} to="/admin/guias"><ItemMenu $margem="10px auto 40px 30px"><FaPersonHiking className="Icone"/>Guias</ItemMenu></Link>
                <Link style={{ textDecoration: 'none', color: "inherit" }} to="/admin/comissoes"><ItemMenu><GiReceiveMoney className="Icone"/>Comissões</ItemMenu></Link>
                <Link style={{ textDecoration: 'none', color: "inherit" }} to="/admin/comissoes/historico"><ItemMenu $margem="10px auto 40px 30px"><MdHistory className="Icone"/>Histórico</ItemMenu></Link>
                <Link style={{ textDecoration: 'none', color: "inherit" }} to="/admin/usuarios"><ItemMenu><FaUserCog className="Icone"/>Usuários</ItemMenu></Link>
            </nav>
        </BarraLateral>
    )
}