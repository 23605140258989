import Cookies from "js-cookie";

export async function listarTickets(busca, filtros, pagina, ordenacao, ordem){
    try {
        let query = `?pagina=${pagina}&ordenacao=${ordenacao}&ordem=${ordem}`;
        if (filtros.tipo) {
            query += "&tipo="; 
            filtros.tipo.forEach( tipo => {
                query += `${tipo.label},`
            });
            query = query.slice(0, -1);
        }
        if (filtros.dataInicial) query += `&dataInicial=${filtros.dataInicial}`;
        if (filtros.dataFinal) query += `&dataFinal=${filtros.dataFinal}`;
        if (busca) query += `&pesquisa=${busca}`;

        const resposta = await fetch(`/api/tickets${query}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return [];
    }
}

export async function ticketsRelatorios(dataInicial, dataFinal) {
    try {
        const resposta = await fetch(`api/tickets-relatorios?dataInicial=${dataInicial}&dataFinal=${dataFinal}`, {
            meethod: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}

export async function criarTickets(dados){
    try {
        const resposta = await fetch("/api/tickets/rapido", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify(dados)
        });
        return await resposta.json();

    } catch (e) {
        console.log(e.message);
        return "Erro";
    }
}

export async function getTicket(numero){
    try {
        const resposta = await fetch(`/api/tickets/busca?numero=${numero}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return "Erro";
    }
}

export async function obterTicketsSessao(sessao){
    try {
        const resposta = await fetch(`/api/tickets/busca?sessao=${sessao}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return [];
    }
}

export async function obterTicketsHoje(){
    try {
        const resposta = await fetch(`/api/tickets/busca?criacaoDataInicial=${new Date().toISOString().split("T")[0]}&criacaoDataFinal=${new Date().toISOString().split("T")[0]}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}

export async function obterTicketsVisitante(visitante){
    try {
        const resposta = await fetch(`/api/tickets/busca?visitante=${visitante}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return [];
    }
}

export async function atualizaEtapa(ticket, etapa){
    try {
        const resposta = await fetch("/api/tickets/etapa-foto", {
            method: "put",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            },
            body: JSON.stringify({
                numero: ticket,
                etapa: etapa
            })
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return "Erro";
    }
}

export async function deletarTicket(ticket){
    try {
        const resposta = await fetch(`/api/tickets/deletar/${ticket}`, {
            method: "delete",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}

export async function obterTicketPorId(id) {
    try {
        const resposta = await fetch(`/api/tickets/id/${id}`, {
            method: "get",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + Cookies.get("token_tour")
            }
        });
        return await resposta.json();
    } catch (e) {
        console.log(e.message);
        return null;
    }
}