import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CabecalhoAdmin from "../../../componentes/CabecalhoAdmin";
import MenuLateral from "../../../componentes/MenuLateral";
import Titulo1 from "../../../componentes/Titulo1";
import Botao from "../../../componentes/Botao";
import ModalObservacao from "../../../componentes/ModalObservacao";
import { AreaSuperior, Inline, SecaoAdmin, SecaoPrincipal } from "../AdminStyled";
import { obterTicketsSessao } from "../../../servicos/ticketsServices";
import { obterSessaoPorId, recalcularLugaresSessao, zerarLugaresSessao } from "../../../servicos/sessoesServices";
import { InfosSessao, VisualizaSessao, VisualizaTickets } from "./SessoesStyled";
import { TabelaTicketsSessao } from "./TabelaTicketsSessao";

export default function SessaoId(){
    const [tickets, setTickets] = useState([]);
    const [sessao, setSessao] = useState(null);
    const [showAviso, setShowAviso] = useState({
        mostrar: false,
        ticket: null
    });
    const [showObs, setShowObs] = useState(false);
    const [justificativa, setJustificativa] = useState("");
    const { id } = useParams();

    useEffect(() => {
        if (!showAviso.mostrar) {
            obterTickets();
            obterSessao();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showAviso]);

    async function obterTickets() {
        const resposta = await obterTicketsSessao(id);
        if (resposta.tickets) setTickets(resposta.tickets);
    }
    
    async function obterSessao() {
        const resposta = await obterSessaoPorId(id);
        if (resposta.sessao) {
            setSessao(resposta.sessao);
            if (resposta.sessao.observacao) setJustificativa(resposta.sessao.observacao);
        }
    }

    async function zerarLugares() {
        const resposta = await zerarLugaresSessao(id, justificativa);
        if (resposta.status === 200) obterSessao();
    }

    async function recalcularLugares() {
        const resposta = await recalcularLugaresSessao(id);
        if (resposta.status === 200) obterSessao();
    }

    const handleConfirma = () => {
        if (justificativa) {
            zerarLugares();
            setShowObs(false);
        }
    }

    const handleCancela = () => {
        setShowObs(false);
    }

    return (
        <SecaoAdmin>
            <MenuLateral />
            <SecaoPrincipal>
                {CabecalhoAdmin("Visualizar sessão")}
                { sessao && 
                <VisualizaSessao>
                    <InfosSessao $margem="15px 4px 15px 0" $cor="#252525">Data:</InfosSessao> 
                    <InfosSessao $bold="bold">{new Date(sessao.diaHora).toLocaleDateString('en-GB')}</InfosSessao>
                    <InfosSessao $margem="15px 4px 15px 0" $cor="#252525">Horário: </InfosSessao>
                    <InfosSessao $bold="bold">{sessao.diaHora.split("T")[1].slice(0,5)}</InfosSessao>
                    <InfosSessao $margem="15px 4px 15px 0" $cor="#252525">Lugares disponíveis: </InfosSessao>
                    <InfosSessao $bold="bold">{sessao.ticketsDisponiveis}</InfosSessao>
                </VisualizaSessao>}
                { tickets && 
                <VisualizaTickets>
                    <AreaSuperior>
                        <Titulo1 $tamanho="1.5em">Ingressos da sessão</Titulo1>
                        <Inline>
                            <Botao $marginRight="10px" onClick={() => recalcularLugares()}>Recalcular lugares</Botao>
                            <Botao $marginRight="20px" onClick={() => setShowObs(true)}>Zerar lugares</Botao>
                        </Inline>
                    </AreaSuperior>
                    {TabelaTicketsSessao(tickets, sessao, showAviso, setShowAviso)}
                    {ModalObservacao("Informe uma justificativa", showObs, handleConfirma, handleCancela, "130px", "500px", justificativa, setJustificativa)}
                </VisualizaTickets>}
            </SecaoPrincipal>
        </SecaoAdmin>
    )
}