import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import QRCode from "qrcode";
import { jsPDF } from "jspdf";
import CabecalhoAdmin from "../../../componentes/CabecalhoAdmin";
import MenuLateral from "../../../componentes/MenuLateral";
import { AreaSuperior, ColunaTabela, LinhaTabela, OpcoesFiltragem, SecaoAdmin, SecaoPrincipal, Tabela } from "../AdminStyled";
import { deletarTicket, obterTicketPorId } from "../../../servicos/ticketsServices";
import { MdPhotoCamera } from "react-icons/md";
import Titulo1 from "../../../componentes/Titulo1";
import Botao from "../../../componentes/Botao";
import ModalPergunta from "../../../componentes/ModalPergunta";
import { obterMensagemPorId } from "../../../servicos/whatsAppServices";
import { reenviarFotoPorWhatsapp } from "../../../servicos/fotosServices";
import ErroSpan from "../../../componentes/ErroSpan";

export default function IngressoId() {
    const [ingresso, setIngresso] = useState(null);
    const [mensagem, setMensagem] = useState(null);
    const [status, setStatus] = useState({
        erro: true,
        mostrar: false,
        mensagem: "Teste"
    })
    const [aviso, setAviso] = useState({
        mostrar: false
    });
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        obterIngresso();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function obterIngresso(){
        const resposta = await obterTicketPorId(id);
        if (resposta.ingresso) {
            if (resposta.ingresso.whatsAppMensagem) {
                const resposta2 = await obterMensagemPorId(resposta.ingresso.whatsAppMensagem);
                if (resposta2.mensagem) setMensagem(resposta2.mensagem);
            }
            setIngresso(resposta.ingresso);
        }
    }

    async function imprimirIngresso(numero, tipo, sessao){
        try {
            const doc = new jsPDF({
                orientation: "landscape",
                unit: "mm",
                format: [90, 50.8]
            });
            doc.setFont(undefined, "bold");
            let pos = 0;
            if (tipo === "PROMOCIONAL-ONLINE") pos = 45; else
            if (tipo === "INFANTIL-ONLINE") pos = 40; else
            if (tipo === "PROMOCIONAL-EXCURSÃO") pos = 47; else
            if (tipo === "PROMOCIONAL") pos = 38; else 
            if (tipo === "CORTESIA") pos = 35; else pos = 33;
            doc.setFontSize(9)
                .text("ACESSO ÚNICO E INDIVIDUAL", 12, 49, null, 90)
                .text(tipo, 17, pos, null, 90);
            doc.setFontSize(8)
                .text("NÃO REEMBOLSÁVEL", 23, 41, null, 90)
                .text("O não comparecimento na sessão", 61, 49, null, 90)
                .text("implica na perda do ingresso", 65, 46, null, 90);
            doc.setFontSize(18).text(sessao.diaHora.split("T")[1].slice(0,5), 75, 35, null, 90);
            doc.setFontSize(12).text(`${new Date(sessao.diaHora).toLocaleDateString('en-GB')}`, 82, 37, null, 90);
            let qrcode = await QRCode.toDataURL(numero.toString());
            doc.addImage(qrcode, 'png', 28, 13, 27, 27);
            
            let iframe = document.createElement('iframe');
            document.body.appendChild(iframe);
            iframe.style.display = "none";
            iframe.src = doc.output("bloburl");
            iframe.onload = () => {
                iframe.contentWindow.print();
            };
        } catch (e) {
            console.log(e.message);
        }
    }

    async function reenviar(){
        const resposta = await reenviarFotoPorWhatsapp([ingresso.numero]);
        if (resposta.status && resposta.status === 200) {
            setStatus({
                mostrar: true,
                mensagem: "A foto foi reenviada!",
                erro: false
            });
        } else {
            setStatus({
                mostrar: true,
                mensagem: resposta.mensagem,
                erro: true
            });
        }
        obterIngresso();
    }

    const handleSim = async () => {
        await deletarTicket(ingresso.numero);
        setAviso({ mostrar: false });
        navigate("/admin/ingressos");
    };
    const handleNao = async () => {
        setAviso({ mostrar: false });
    };

    return (
        <SecaoAdmin>
            <MenuLateral />
            <SecaoPrincipal>
                {CabecalhoAdmin("Visualizar ingresso")}
                {ModalPergunta("Tem certeza que deseja deletar este ingresso?", handleSim, handleNao, aviso.mostrar, "100px")}
                {ingresso && <>
                <AreaSuperior>
                    <Titulo1 $margem="0 0 0 0" $tamanho="20px">{ingresso.numero}</Titulo1>
                    <OpcoesFiltragem>
                        {ingresso.foto && ingresso.visitante.celular && <Botao $marginRight="10px" onClick={() => reenviar()}>Reenviar foto</Botao>}
                        <Botao $marginRight="10px" onClick={() => imprimirIngresso(ingresso.numero, ingresso.tipo, ingresso.sessao)}>Imprimir</Botao>
                        <Botao $marginRight="10px" $cor="#ff2424" onClick={() => setAviso({ mostrar: true })} >Excluir</Botao>
                    </OpcoesFiltragem>
                </AreaSuperior>
                {status.mostrar && <ErroSpan $corFundo={status.erro? "#ffcdcd": "#ddffcd"} $corTexto={status.erro? "#9e0000": "#209e00"}>{status.mensagem}</ErroSpan>}
                <Tabela>
                    <tbody>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px">Sessão</ColunaTabela>
                            <ColunaTabela><Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/sessoes/${ingresso.sessao._id}`}>{new Date(ingresso.sessao.diaHora).toLocaleDateString('en-GB')}, {ingresso.sessao.diaHora.split("T")[1].slice(0,5)}</Link></ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px">Visitante</ColunaTabela>
                            <ColunaTabela><Link style={{ textDecoration: 'none', color: "inherit" }} to={`/admin/visitantes/${ingresso.visitante._id}`}>{ingresso.visitante.nome || ingresso.visitante.email || ingresso.visitante.celular || "-"}</Link></ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px">Tipo</ColunaTabela>
                            <ColunaTabela>{ingresso.tipo}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px">Motivo da cortesia</ColunaTabela>
                            <ColunaTabela>{ingresso.motivoCortesia || "-"}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px">Guia</ColunaTabela>
                            <ColunaTabela>{ingresso.guia? ingresso.guia.nome : "-"}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px">Data da compra</ColunaTabela>
                            <ColunaTabela>{ingresso.dataCriacao? new Date(ingresso.dataCriacao).toLocaleString("en-gb") : "-"}</ColunaTabela>
                        </LinhaTabela>
                        <LinhaTabela>
                            <ColunaTabela $cor="#252525" $tamanho="200px">Foto</ColunaTabela>
                            <ColunaTabela>{ingresso.foto? <Link style={{ textDecoration: 'none', color: "inherit" }} target={"_blank"} to={`/foto/${ingresso.foto}`}><MdPhotoCamera className="Icone"/></Link>: "-"}</ColunaTabela>
                        </LinhaTabela>
                        {mensagem && <LinhaTabela $tamanho="70px">
                            <ColunaTabela $cor="#252525" $tamanho="200px">Envio por WhatsApp</ColunaTabela>
                            <ColunaTabela $cor="#252525" $tamFonte="14px">{mensagem.status.map( s => {
                                const s2 = JSON.parse(s);
                                const att = s2.atualizacao === "accepted"? "Aceita": s2.atualizacao === "sent"? "Enviada": s2.atualizacao === "delivered"? "Entregue": s2.atualizacao === "read"? "Lida": s2.atualizacao === "failed"? "Erro no envio": "Não identificado";
                                return `${att}: ${new Date(s2.horario*1000).toLocaleString("en-gb")}. `
                            })}</ColunaTabela>
                        </LinhaTabela>}
                    </tbody>
                </Tabela>
                </>}
            </SecaoPrincipal>
        </SecaoAdmin>
    )
}