import styled from "styled-components";

export const PaginaTickets = styled.section`
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    display: flex;
`
export const MenuLateral = styled.div`
    background-color: var(--corNugaliEscura);
    width: 200px;
    height: 100%;
    flex-shrink: 0;
    justify-content: center;
    text-align: center;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 5px;
        height: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--corNugaliClara);
        border-radius: 10px;
    }
`
export const SecaoPrincipal = styled.div`
    height: 100%;
    width: 100%;
    background-color: #eedfc8e1;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 5px;
        height: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--corNugaliClara);
        border-radius: 10px;
    }
    h1 {
        margin: 0;
        padding: 0;
        color: #ffffff;
    }
`
export const ListaSessoes = styled.div`
    background-color: ${props => props.$corFundo || "transparent"};
    color: ${props => props.$vazio? "red": "white"};
    margin: 0;
    padding: 5px;
    font-size: 14px;
`
export const Texto = styled.p`
    color: ${props => props.$cor || "var(--corNugaliEscura)"};
    font-size: ${props => props.$tamanho || "16px"};
    background-color: ${props => props.$corFundo || "transparent"};
    margin: ${props => props.$margem || "5px"};
    padding: ${props => props.$pad || "0"};
    font-weight: ${props => props.$negrito || "normal"};
    border-radius: 3px;
`
export const LadoALado = styled.div`
    display: flex;
    justify-content: ${props => props.$justificar || "center"};
    align-items: center;
    .IconeAtt {
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
`
export const DivTickets = styled.div`
    text-align: left;
    margin: ${props => props.$margem || "10px"};
    width: ${props => props.$largura || "100%"};
    display: ${props => props.$esconder? "none": "block"};
    padding: ${props => props.$pad || "0"};
    min-width: ${props => props.$larguraMinima || ""};
    box-sizing: border-box;
`
export const InputsTickets = styled.input`
    margin: ${props => props.$margem || "0"};
    width: ${props => props.$largura || "100%"};
    border: none;
    border-bottom: 2px solid var(--corNugaliEscura);
    box-sizing: border-box;
    padding: 10px;
    font-size: 24px;
`
export const CheckboxContainer = styled.div`
    width: 100%;
    height: 50%;
    margin-top: 30px;
    border-radius: 10px;
    border: 1px solid var(--corNugaliEscura);
    background-color: ${props => props.checked? "#97C160": "transparent"};
    display: flex;
    align-items: center;
`
export const CheckboxLabel = styled.label`
    color: ${props => props.checked ? "#FFF" : "var(--corNugaliEscura)"};
`
export const StyledCheckbox = styled.label`
    width: 23px;
    height: 23px;
    margin-right: 6px;
    margin-left: 6px;
    border-radius: 50%;
    background: #F6F6F6;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        display: ${props => props.checked ? 'flex' : 'none'};
        filter: invert(75%) sepia(11%) saturate(6042%) hue- rotate(30deg) brightness(105%) contrast(68%);
    }
`
export const HiddenCheckbox = styled.input`
    overflow: hidden;
    white-space: nowrap;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    display: "none";
`
export const LabelsTickets = styled.label`
    display:inline-block;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 5px;
    margin-right: ${props => props.$marginRight || "0"};
`
export const AreaBotoesTickets = styled.div`
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    margin: 0;
`
export const AreaNaoImprimiu = styled.div`
    width: 100%;
    display: inline-flex;
    align-items: center;
    margin: 0;
`
export const BotaoNaoImprimiu = styled.button`
    background-color: #910101;
    border: none;
    margin: 20px 0 0 50px;
    padding: 15px;
    color: white;
    font-size: 18px;
    cursor: pointer;
    display: ${props => props.$esconder? "none": "block"};
    &:disabled{
        background-color: #91010118;
        cursor: auto;
    }
`
export const BotaoSalvar = styled.button`
    background-color: transparent;
    border: none;
    font-size: 24px;
    font-weight: 800;
    color: var(--corNugaliEscura);
    cursor: pointer;
    margin: ${props => props.$margem || "20px 50px 0 0"};
    
    &:disabled{
        color: lightgray;
        border-color: lightgray;
        cursor: ${props => props.$cursorDesabilitado || "not-allowed"};
        box-shadow: none;
    }
`