import styled from "styled-components";

const Tabela = styled.table`
    width: 90%;
    justify-content: center;
    text-align: center;
    border-collapse: collapse;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 40px;
    overflow-x: auto;
`
const LinhaTabela = styled.tr`
    border-bottom: 1px solid lightgray;
    height: ${props => props.$tamanho || "40px"};
`
const ColunaTabela = styled.td`
    width: ${props => props.$tamanho || "auto"};
    color: ${props => props.$cor || "#252525"};
    font-size: ${props => props.$tamFonte || "inherit"};
    font-weight: ${props => props.$bold || "normal"};

    .Icone {
        margin: 5px 5px 0 0;
        padding: 0;
    }
`

export function TabelaPorDia(dados) {
    try {
        return (
            <Tabela>
                <LinhaTabela>
                    <ColunaTabela $bold="bold">Dia</ColunaTabela>
                    <ColunaTabela $bold="bold">Total</ColunaTabela>
                    <ColunaTabela $bold="bold">Promocionais</ColunaTabela>
                    <ColunaTabela $bold="bold">Infantis</ColunaTabela>
                    <ColunaTabela $bold="bold">Cortesias</ColunaTabela>
                    <ColunaTabela $bold="bold">Promocionais Online</ColunaTabela>
                    <ColunaTabela $bold="bold">Infantis Online</ColunaTabela>
                    <ColunaTabela $bold="bold">Promocionais Excursão</ColunaTabela>
                </LinhaTabela>
                {dados.dias.map((dia, indice) => {
                    return <LinhaTabela>
                        <ColunaTabela>{dia.split("-")[2]}/{dia.split("-")[1]}/{dia.split("-")[0]}</ColunaTabela>
                        <ColunaTabela>{dados.quantidade[indice].promocionais + dados.quantidade[indice].promocionaisOnline + dados.quantidade[indice].promocionaisExcursao + dados.quantidade[indice].infantis + dados.quantidade[indice].infantisOnline + dados.quantidade[indice].cortesias}</ColunaTabela>
                        <ColunaTabela>{dados.quantidade[indice].promocionais}</ColunaTabela>
                        <ColunaTabela>{dados.quantidade[indice].infantis}</ColunaTabela>
                        <ColunaTabela>{dados.quantidade[indice].cortesias}</ColunaTabela>
                        <ColunaTabela>{dados.quantidade[indice].promocionaisOnline}</ColunaTabela>
                        <ColunaTabela>{dados.quantidade[indice].infantisOnline}</ColunaTabela>
                        <ColunaTabela>{dados.quantidade[indice].promocionaisExcursao}</ColunaTabela>
                    </LinhaTabela>
                })}
            </Tabela>
        );
    } catch (e) {
        console.log(e.message);
        return <></>
    }
}