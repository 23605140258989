import { useState } from "react";
import { QrReader } from "react-qr-reader";
import CabecalhoAdmin from "../../../componentes/CabecalhoAdmin";
import MenuLateral from "../../../componentes/MenuLateral";
import ModalPergunta from "../../../componentes/ModalPergunta";
import { AreaSuperior, InputsGuia, SecaoAdmin, SecaoPrincipal } from "../AdminStyled";
import { deletarTicket, getTicket } from "../../../servicos/ticketsServices";
import { AreaBotoes, AreaCamera, AreaCancelamento, InfosIngresso, LadoALado } from "./IngressosStyled";
import Titulo1 from "../../../componentes/Titulo1";
import Botao from "../../../componentes/Botao";

export default function CancelarIngresso(){
    const [ingressoIn, setIngressoIn] = useState("")
    const [ingresso, setIngresso] = useState(null);
    const [carregando, setCarregando] = useState(false);
    const [aviso, setAviso] = useState({
        mostrar: false
    });

    async function obterIngresso(numero){
        if (!numero) return;
        setCarregando(true);
        const resposta = await getTicket(numero);
        if (resposta && resposta.status === 200) setIngresso(resposta.tickets[0]);
        setCarregando(false);
    }

    const handleSim = async () => {
        await deletarTicket(ingresso.numero);
        setIngresso(null);
        setIngressoIn("");
        setAviso({ mostrar: false });
    };
    const handleNao = async () => {
        setAviso({ mostrar: false });
    };

    return (
        <SecaoAdmin>
            <MenuLateral />
            <SecaoPrincipal>
                {CabecalhoAdmin("Cancelar Ingressos")}
                {ModalPergunta("Tem certeza que deseja deletar este ingresso?", handleSim, handleNao, aviso.mostrar, "100px")}
                <AreaSuperior>
                    <Titulo1 $margem="0 0 0 0" $tamanho="20px">Posicione o ingresso em frente à câmera ou digite seu número</Titulo1>
                </AreaSuperior>
                <LadoALado>
                    <AreaCamera>
                        <QrReader
                                onResult={(resultado) => {
                                    if (!!resultado) {
                                        setIngressoIn(resultado.text)
                                        obterIngresso(resultado.text)
                                    }
                                }}
                            />
                    </AreaCamera>
                    <AreaCancelamento>
                        <InputsGuia value={ingressoIn} onChange={(e) => setIngressoIn(e.target.value)}/>
                        <AreaBotoes>
                            <Botao $marginRight="20px" disabled={carregando} onClick={() => obterIngresso(ingressoIn)}>Pesquisar</Botao>
                            <Botao $corFundo="#ff1c1c" $cor="#ffffff" disabled={ingresso?.numero? false: true || carregando} onClick={() => setAviso({mostrar: true})}>DELETAR</Botao>
                        </AreaBotoes>
                    </AreaCancelamento>
                </LadoALado>
                {ingresso?.numero && <>
                    <InfosIngresso>{`Ingresso ${ingresso.numero}`}</InfosIngresso>
                    <InfosIngresso>{`Tipo: ${ingresso.tipo}`}</InfosIngresso>
                    <InfosIngresso>{`Sessão: ${ingresso.sessao.diaHora.split("T")[0]}, ${ingresso.sessao.diaHora.split("T")[1].slice(0,5)}`}</InfosIngresso>
                    <InfosIngresso>{ingresso.foto? "Ingresso utilizado!": "Ingresso não utilizado."}</InfosIngresso>
                </>}
            </SecaoPrincipal>
        </SecaoAdmin>
    )
}