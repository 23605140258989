import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { InputMask } from "@react-input/mask";
import { jsPDF } from "jspdf";
import Select from "react-select";
import QRCode from "qrcode";
import Titulo1 from "../../componentes/Titulo1.jsx";
import Linha from "../../componentes/Linha.jsx";
import { listarGuias } from "../../servicos/guiasServices.js";
import { criarTickets } from "../../servicos/ticketsServices.js";
import { adicionarTicketsExtra, buscaSessao } from "../../servicos/sessoesServices.js";
import { AreaBotoesTickets, AreaNaoImprimiu, BotaoNaoImprimiu, BotaoSalvar, DivTickets, InputsTickets, LabelsTickets, LadoALado, ListaSessoes, MenuLateral, PaginaTickets, SecaoPrincipal, Texto } from "./TicketsStyled.jsx";

export default function CriarTickets() {
    const navigate = useNavigate();
    const [sessoes, setSessoes] = useState([]);
    const [reimprimir, setReimprimir] = useState(false);
    const [dia, setDia] = useState(new Date().toISOString().split("T")[0])
    const [dados, setDados] = useState({
        infantis: "0",
        cortesias: "0",
        promocionaisExcursao: "0",
        promocionaisOnline: "0",
        visitante: {nome: "", email: "", celular: ""},
        motivoCortesia: ""
    });
    const [guias, setGuias] = useState([]);
    const [carregando, setCarregando] = useState(false);
    const motivoCort = useRef(null);

    useEffect(() => {
        obterSessoes(dia);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dia]);

    async function obterSessoes(dia) {
        buscaSessao(dia).then(resposta => {
            if (resposta.status === 401) return navigate("/login", { state: { redirecionar: window.location.pathname }});
            let listagemSessoes = [];
            resposta.dados.map( sessao => {
                return listagemSessoes.push({
                    value: sessao._id,
                    label: sessao.diaHora.slice(11,16),
                    ticketsDisponiveis: sessao.ticketsDisponiveis,
                    diaHora: sessao.diaHora,
                    extrasAdicionados: sessao.extrasAdicionados
                });
            });
            setSessoes(listagemSessoes);
            setDados({sessao: listagemSessoes.find( s => s.ticketsDisponiveis > 0), motivoCortesia: "", horarioEspecial: dados.horarioEspecial, guia: dados.guia || "", promocionaisExcursao: "0", promocionaisOnline: "0", infantis: "0", cortesias: "0", visitante: {nome: "", email: "", celular: ""},
            segundaChamada: true});
        });
        listarGuias().then(resposta => {
            if (resposta.status === 401) return navigate("/login", { state: { redirecionar: window.location.pathname }});
            let listagemGuias = [];
            resposta.map(guia => {
                return listagemGuias.push({
                    value: guia._id,
                    label: guia.nome.toString()
                })
            });
            setGuias(listagemGuias);
        });
    }

    async function salvar(){
        if (Number(dados.cortesias + dados.infantis + dados.promocionaisExcursao + dados.promocionaisOnline) === 0) return;
        if (Number(dados.cortesias) > 0 && !dados.motivoCortesia) return motivoCort.current.focus();
        setCarregando(true);
        try {
            criarTickets({ ...dados, sessao: dados.sessao.value }).then(async resposta => {
                const doc = new jsPDF();
                doc.setFont(undefined, "bold");
                doc.setFontSize(15);
                doc.text("Estes são seus ingressos para o Tour Nugali Chocolates!", 105, 15, "center");
                doc.setFontSize(20).text(`Dia ${dados.sessao.diaHora.slice(8,10)}/${dados.sessao.diaHora.slice(5,7)}/${dados.sessao.diaHora.slice(0,4)} às ${dados.sessao.label}`, 105, 25, "center");
                doc.setFontSize(12).text("Chegue com antecedência e tenha os ingressos em mãos.", 105, 32, "center");
                doc.text("Aproxime o Código QR nos leitores das catracas no horário indicado.", 105, 39, "center");
                doc.text("O não comparecimento na data e horário agendados resultará na perda destes ingressos.", 105, 46, "center");
                for (let i = 0; i < resposta.tickets.length; i ++) {
                    let qrcode = await QRCode.toDataURL(resposta.tickets[i].numero.toString());
                    doc.addImage(qrcode, 'png', 5 + 43*(i%5), 55 + 43*Math.floor(i/5), 27, 27);     // x, y, largura, altura
                    doc.text(resposta.tickets[i].tipo.split("-")[0], 19 + 43*(i%5), 85 + 43*Math.floor(i/5), "center");
                }
                setReimprimir(doc);
                let iframe = document.createElement('iframe');
                document.body.appendChild(iframe);
                iframe.style.display = "none";
                iframe.src = doc.output("bloburl");
                iframe.onload = () => {
                    iframe.contentWindow.print();
                };
                obterSessoes(dia);
                setCarregando(false);
            });
        } catch (e) {
            console.log(e.message);
        }
    }

    function naoImprimiu(){
        let iframe = document.createElement('iframe');
            document.body.appendChild(iframe);
            iframe.style.display = "none";
            iframe.src = reimprimir.output("bloburl");
            iframe.onload = () => {
                iframe.contentWindow.print();
        }
    }

    async function adicionarExtras(){
        adicionarTicketsExtra(dados.sessao.value);
        setDados({...dados, sessao: {...dados.sessao, ticketsDisponiveis: dados.sessao.ticketsDisponiveis + 2}});
    }

    if (sessoes.length === 0 || guias.length === 0) {
        return (<span>Carregando</span>);
    } else {
        let fundo = "transparent";
        return (
            <PaginaTickets>
                <MenuLateral>
                    <Titulo1 $alinhamento="center" $cor="var(--corNugaliClara)" $corFundo="transparent" $tamanho="1.5em">
                        Sessões e Lugares Criar
                    </Titulo1>
                    {sessoes.slice(0, -7).map(sessao => {
                        fundo = fundo === "transparent"? "#ac6c5c5f": "transparent";
                        return (
                            <ListaSessoes $vazio={sessao.ticketsDisponiveis === 0} key={sessao.value} $corFundo={fundo}>{sessao.label} : {sessao.ticketsDisponiveis}</ListaSessoes>
                        )
                    })}
                </MenuLateral>
                <SecaoPrincipal>
                    <Texto $margem="20px 10px 20px 10px" $negrito="bold" $tamanho="30px">{`${dados.sessao.ticketsDisponiveis} lugares disponíveis na sessão escolhida`}</Texto>
                    <Linha />
                    <DivTickets $margem="15px 10px 10px 50px">
                        <LabelsTickets $marginRight="10px">Dia</LabelsTickets>
                        <InputsTickets $largura="200px" type="date" onChange={event => setDia(event.target.value)} value={dia}/>
                    </DivTickets>
                    <LadoALado>
                        <DivTickets $largura="400px" $margem="15px 10px 15px 50px">
                            <LabelsTickets>Sessão</LabelsTickets>
                            <Select 
                                options={sessoes} 
                                value={dados.sessao}
                                onChange={(e) => setDados({...dados, sessao: e})}
                                placeholder=""
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        margin: 0,
                                        border: "none",
                                        padding: "2px",
                                        borderRadius: 0,
                                        borderBottom: "2px solid #3a170e",
                                        fontSize: "24px",
                                        textAlign: "center",
                                        borderColor: state.hover ? 'black' : 'rgb(58, 23, 14)' 
                                    }),
                                    option: (baseStyles) => ({
                                        ...baseStyles,
                                        color: "black",
                                        textAlign: "center",
                                        fontSize: "20px"
                                    }),
                                    singleValue: (baseStyles) => ({
                                        ...baseStyles,
                                        color: "black"
                                    })
                                }}
                            />
                        </DivTickets>
                        <DivTickets $margem="15px 50px 15px 10px">
                            <LabelsTickets>Guia</LabelsTickets>
                            <Select 
                                options={guias} 
                                isClearable
                                onChange={(e) => {
                                    if(e) setDados({...dados, guia: e.value}); else setDados(atual => {
                                        const {guia, ...resto} = atual;
                                        return resto;
                                    })}
                                }
                                placeholder=""
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        margin: 0,
                                        border: "none",
                                        padding: "2px",
                                        borderRadius: 0,
                                        borderBottom: "2px solid #3a170e",
                                        fontSize: "24px",
                                        borderColor: state.hover ? 'black' : 'rgb(58, 23, 14)' 
                                    }),
                                    option: (baseStyles) => ({
                                        ...baseStyles,
                                        color: "black",
                                        fontSize: "20px"
                                    }),
                                    singleValue: (baseStyles) => ({
                                        ...baseStyles,
                                        color: "black"
                                    })
                                }}
                            />
                        </DivTickets>
                    </LadoALado>
                    <LadoALado>
                        <DivTickets $margem="15px 10px 30px 50px">
                            <LabelsTickets>Promocional Excursão</LabelsTickets>
                            <InputsTickets type="number" onChange={event => setDados({...dados, promocionaisExcursao: event.target.value})} value={dados.promocionaisExcursao} min={0} max={dados.sessao?.ticketsDisponiveis - dados.promocionaisOnline - dados.cortesias - dados.infantis || 0}/>
                        </DivTickets>
                        <DivTickets $margem="15px 10px 30px 10px">
                            <LabelsTickets>Promocional Online</LabelsTickets>
                            <InputsTickets type="number" onChange={event => setDados({...dados, promocionaisOnline: event.target.value})} value={dados.promocionaisOnline} min={0} max={dados.sessao?.ticketsDisponiveis - dados.cortesias - dados.promocionaisExcursao - dados.infantis || 0}/>
                        </DivTickets>
                        <DivTickets $margem="15px 10px 30px 10px">
                            <LabelsTickets>Infantil</LabelsTickets>
                            <InputsTickets type="number" onChange={event => setDados({...dados, infantis: event.target.value})} value={dados.infantis} min={0} max={dados.sessao?.ticketsDisponiveis - dados.promocionaisOnline - dados.cortesias - dados.promocionaisExcursao || 0}/>
                        </DivTickets>
                        <DivTickets $margem="15px 50px 30px 10px">
                            <LabelsTickets>Cortesia</LabelsTickets>
                            <InputsTickets type="number" onChange={event => setDados({...dados, cortesias: event.target.value})} value={dados.cortesias} min={0} max={dados.sessao?.ticketsDisponiveis - dados.promocionaisOnline - dados.promocionaisExcursao - dados.infantis || 0}/>
                        </DivTickets>
                    </LadoALado>
                    <DivTickets $esconder={Number(dados.cortesias) === 0} $margem="0px 100px 30px 50px" $pad="0 100px 0 0">
                        <LabelsTickets>Motivo da cortesia</LabelsTickets>
                        <InputsTickets ref={motivoCort} value={dados.motivoCortesia} onChange={(event => setDados({...dados, motivoCortesia: event.target.value}))}/>
                    </DivTickets>
                    <Linha />
                    <LadoALado>
                        <DivTickets $margem="15px 10px 30px 50px">
                            <LabelsTickets>Nome</LabelsTickets>
                            <InputsTickets onChange={(e) => setDados({...dados, visitante: {...dados.visitante, nome: e.target.value}})} value={dados.visitante.nome} />
                        </DivTickets>
                        <DivTickets $margem="15px 10px 30px 10px">
                            <LabelsTickets>E-mail</LabelsTickets>
                            <InputsTickets onChange={(e) => setDados({...dados, visitante: {...dados.visitante, email: e.target.value}})} value={dados.visitante.email} />
                        </DivTickets>
                        <DivTickets $margem="15px 50px 30px 10px" $largura="80%">
                            <LabelsTickets>Celular</LabelsTickets>
                            <InputMask onInput={event => setDados({...dados, visitante: {...dados.visitante, celular: event.target.value}})} value={dados.visitante.celular} component={InputsTickets} mask="(__) _____-____" replacement={{ _: /\d/ }}/>
                        </DivTickets>
                    </LadoALado>
                    <AreaBotoesTickets>
                        <AreaNaoImprimiu>
                            <BotaoNaoImprimiu disabled={!reimprimir} onClick={() => naoImprimiu()}>Não imprimiu</BotaoNaoImprimiu>
                            <BotaoNaoImprimiu disabled={dados.sessao.extrasAdicionados} onClick={() => adicionarExtras()}>Adicionar Tickets extra</BotaoNaoImprimiu>
                        </AreaNaoImprimiu>
                        <BotaoSalvar disabled={carregando} onClick={() => salvar()}>SALVAR</BotaoSalvar>
                    </AreaBotoesTickets>
                </SecaoPrincipal>
            </PaginaTickets>
        )
    }
}