import { useEffect, useState } from "react";
import CabecalhoAdmin from "../../componentes/CabecalhoAdmin";
import MenuLateral from "../../componentes/MenuLateral";
import { AreaGrafico, AreaSuperior, FiltrarData, Inline2, InputMes, OpcoesFiltragem, SecaoAdmin, SecaoPrincipal } from "./AdminStyled";
import GraficoBarras from "./GraficoBarras";
import { ticketsRelatorios } from "../../servicos/ticketsServices";
import { TabelaTotais } from "./TabelaTotais";
import { TabelaPorDia } from "./TabelaPorDia";
import { TabelaPorGuia } from "./TabelaPorGuia";

export default function Admin() {
    const [filtros, setFiltros] = useState({
        dataInicial: new Date (new Date().getTime() - 60*60*24*7*1000).toISOString().split("T")[0],
        dataFinal: new Date().toISOString().split("T")[0]
    });
    const [totais, setTotais] = useState(null);
    const [dadosRelatorio, setDadosRelatorio] = useState(null);
    const [totaisPorDia, setTotaisPorDia] = useState(null);
    const [totaisPorGuia, setTotaisPorGuia] = useState(null);

    useEffect(() => {
        ticketsRelatorios(filtros.dataInicial, filtros.dataFinal).then( resposta => {
            setDadosRelatorio({
                labels: resposta.porHora.horarios,
                datasets: [
                    {
                        label: "Ingressos por hora",
                        data: resposta.porHora.quantidade,
                        backgroundColor: 'rgba(99, 141, 255, 0.589)'
                    }
                ]
            });
            setTotaisPorDia(resposta.porDia);
            setTotaisPorGuia(resposta.porGuia);
            setTotais(resposta.totais)
        });
    }, [filtros]);

    return (
        <SecaoAdmin>
            <MenuLateral />
            <SecaoPrincipal>
                {CabecalhoAdmin("Administração Tour Nugali")}
                <AreaSuperior>
                    <OpcoesFiltragem>
                        <FiltrarData $marginTop="1px">
                            <label>Data inicial</label>
                            <InputMes type="date" defaultValue={filtros.dataInicial} onChange={event => setFiltros({...filtros, dataInicial: event.target.value})}/>
                        </FiltrarData>
                        <FiltrarData $marginTop="1px">
                            <label>Data final</label>
                            <InputMes type="date" defaultValue={filtros.dataFinal} onChange={event => setFiltros({...filtros, dataFinal: event.target.value})}/>
                        </FiltrarData>
                    </OpcoesFiltragem>
                </AreaSuperior>
                <Inline2>
                    <AreaGrafico $margem="30px 10px 30px 40px" $largura="50%"> {(dadosRelatorio && GraficoBarras("Relatório", dadosRelatorio)) || <p>Carregando</p>} </AreaGrafico>
                    <AreaGrafico $margem="50px 40px 30px 10px" $largura="50%">{(totais && TabelaTotais(totais)) || <p>Carregando</p>}</AreaGrafico>
                </Inline2>
                {totaisPorDia && TabelaPorDia(totaisPorDia)}
                {totaisPorGuia && TabelaPorGuia(totaisPorGuia)}
            </SecaoPrincipal>
        </SecaoAdmin>
    )
}